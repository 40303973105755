<template>
  <div class="card">
    <h1 class="page__title card__title">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: { title: { type: String, required: true } }
}
</script>

<style scoped>
.card {
  width: 100%;
  text-align: left;
  padding: 25px;
  margin-bottom: 2rem;
}

.card__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
}
</style>
